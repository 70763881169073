import React, { useState, useEffect } from 'react';
import { Col, Row, Select as SelectAntd } from 'antd';
import { FormattedMessage } from 'react-intl';
import { any, object, func, string } from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Select from '@/components/Form/Select';
import SetTimezone from '@/components/SetTimezone';
import { Tooltip } from '@/components/Tooltip';
import { getId } from '@/utils/global';

import { Alert } from '@seekube-tech/ui-kit';
import { useFormatMessage } from 'react-intl-hooks';
import { getAvailableHours } from '../../../../request';

import messages from '../../../messages';
import styles from './styles.less';

const { Option } = SelectAntd;

const BeginAt = ({
  getFieldDecorator,
  setFieldsValue,
  getFieldValue,
  conference,
  userTimezone,
  dateAvailable,
  availableHours = {},
  duration = '15',
  intl,
}) => {
  const t = useFormatMessage();
  const formatDate = (date, format = 'HH:mm') => moment(date).format(format)
  return (
    <>
      <FormattedMessage {...messages.dateTitle} tagName="h1" />
      <SetTimezone />

      <Alert
        color='info'
        children={<FormattedMessage {...messages.canDuplicate} />}
        isClosable={false}
        className={styles.alertInfoDate}
      />
      <Row gutter={30}>
        <Col span={6}>
          {
            getFieldDecorator('date', {
              initialValue: isEmpty(conference.beginAt) ? null : conference.beginAt,
              rules: [{ required: true, message: <FormattedMessage {...messages.mandatory} /> }],
            })(
              <Select
                addOnIcon="calendar"
                placeholder="Date"
                required={<FormattedMessage {...messages.mandatory} />}
                label="Date"
                onChange={(e) => getAvailableHours(e, getId(conference._event), conference._id, userTimezone).then((res) => { setFieldsValue({ beginAt: res[0].date }); })}
              >
                {
                  dateAvailable.map((duration) => (<Option key={duration}>{`${duration}`}</Option>))
                }
              </Select>
            )
          }
        </Col>
        <Col span={6}>
          {
            getFieldDecorator('beginAt', {
              initialValue: isEmpty(conference.beginAt) ? null : conference.beginAt,
              rules: [{ required: true, message: <FormattedMessage {...messages.mandatory} /> }],
            })(
              <Select
                addOnIcon="clock"
                disabled={isEmpty(getFieldValue('date'))}
                placeholder={<FormattedMessage {...messages.conferenceBeginLabel} />}
                label={intl.formatMessage(messages.conferenceBeginLabel)}
                required={<FormattedMessage {...messages.mandatory} />}
              >
                {
                  Object.entries(availableHours)
                    .map(
                      (date, key) => {
                        /**
                         * If conference hour is behind us
                         * Or if it is disabled by the api 
                         */
                        const isDisabled = typeof date[1][duration] !== 'boolean'
                        return (
                          <Option
                            disabled={isDisabled}
                            /**
                             * We have to set the utc according to user to save it correctly
                             */
                            key={key}
                            value={date[0]}
                          >
                            {isDisabled ?
                              <Tooltip
                                placement="left"
                                overlayClassName={styles.tooltip}
                                color="dark"
                                isVisible
                                title={`${date[1][duration] === '' ? date[1][duration] : date[1]} ${t({id: 'tooltip.hour.booked'})}`}
                              >
                                <div className={styles.disableHour}>
                                  <span>{formatDate(moment.tz(date[0], userTimezone.utc[0]))}</span>
                                </div>
                              </Tooltip> : formatDate(moment.tz(date[0], userTimezone.utc[0]))}</Option>)
                      }
                    )
                }
              </Select>
            )
          }
        </Col>
      </Row>
    </>
  );
};

BeginAt.propTypes = {
  getFieldDecorator: func,
  setFieldsValue: func,
  getFieldValue: func,
  conference: object,
  intl: object,
  userTimezone: string,
  eventTimezone: string,
  dateAvailable: any,
};

export { BeginAt };
