import moment from 'moment';
import { requestFile } from '@/utils/request';
import { objectToParams } from '@/utils/url';

/**
 * Returns dates as UTC values for live talks on an event
 * 
 * @param {*} date 
 * @param {*} eventId 
 * @param {*} conferenceId 
 * @returns 
 */
const getAvailableHours = async (date, eventId, conferenceId, userTimezone) => {
  moment.tz.setDefault('UTC');
  const date2 = moment(date)._isValid ? 
    // pour éviter de recevoir le jour de la veille avec une tz négative
    moment(date).add(- userTimezone.offset, 'h') 
    : 
    moment(date, 'DD MMMM YYYY').add(- userTimezone.offset, 'h');
  const params = objectToParams({ eventId, conferenceId, date: date2.format('YYYY-MM-DD') });
  const requestUrl = `${process.env.FRONT_API_URL}/conferences/checkDate?${params}`;
  moment.tz.setDefault(userTimezone.utc[0]);

  return requestFile(requestUrl);
};

export { getAvailableHours };
